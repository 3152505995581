import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from 'components/logo';
import {
  HeaderWrap,
  HeaderBottom,
  FixedHeader,
  HeaderMain,
  HeaderLeft,
  FixedHeaderHeight,
} from './header.style';
import useHeader from './useHeader';
import { FC } from 'react';

export interface HeaderProps {
  fixedDisplayVarient?: boolean;
  mainMenuStyle?: any;
  burgerBtnElStyle?: any;
  offCanvasStyle?: any;
  mobileMenuStyle?: any;
}

const Header: FC<HeaderProps> = (props) => {
  const [sticky, setSticky] = useState<boolean>(false);

  const onScrollHandler = ({ isDocked }: { isDocked: boolean }) => setSticky(!isDocked);

  const headerRef = useRef(null);
  const fixedRef = useRef(null);

  const {
    social,
    fixedHeaderHeight,
  } = useHeader({
    onScrollHandler,
    headerRef,
    fixedRef,
  });

  const {
    fixedDisplayVarient,
  } = props;

  const shouldDisplayFixedVarient = sticky || fixedDisplayVarient;

  return (
    <Fragment>
      <HeaderWrap ref={headerRef} displayVarient={!shouldDisplayFixedVarient}>
        <HeaderBottom>
          <FixedHeader
            ref={fixedRef}
            isSticky={sticky}
            bgColor={!shouldDisplayFixedVarient ? 'transparent' : 'white'}
          >
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <HeaderMain>
                    <HeaderLeft>
                      <Logo
                        variant={
                          !shouldDisplayFixedVarient ? 'white' : 'primary'
                        }
                      />
                    </HeaderLeft>
                  </HeaderMain>
                </Col>
              </Row>
            </Container>
          </FixedHeader>
          <FixedHeaderHeight
            height={!shouldDisplayFixedVarient ? 0 : fixedHeaderHeight}
          />
        </HeaderBottom>
      </HeaderWrap>
    </Fragment>
  );
};

Header.propTypes = {
  mainMenuStyle: PropTypes.object,
};

Header.defaultProps = {
  mainMenuStyle: {
    color: '#8c8c8c',
    fontWeight: 600,
  },
};

export default Header;
